<template>
  <svg
    height="40"
    width="40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 511.998 511.998"
    xml:space="preserve"
  >
  </svg>
</template>

<script>
export default {
  name: 'ProjectLogo'
}
</script>
